import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import { FormattedMessage } from "react-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.jpg"
import BioDataContentPropType from "../utils/bio-content-proptype"

const Logo = ({ data }) => {
  const {
    contentfulBio: {
      text: {
        // text,
        childMarkdownRemark: { html },
      },
    },
  } = data
  return (
    <Layout>
      <SEO title="Biography" />
      <div
        css={css`
          width: 80%;
          margin: 50px auto;
          color: #f8f2f2;
        `}
      >
        <div
          css={css`
            margin: 0px auto;
          `}
        >
          <img
            css={css`
              max-width: 100%;
            `}
            src={logo}
            alt="Samer"
          />
        </div>
        <div
          css={css`
            text-align: center;
            margin: 3px auto 20px auto;

            a {
              color: rgba(236, 223, 227, 0.6);
              text-decoration: none;
            }
          `}
        >
          <FormattedMessage id="logo.maker.label" />:{"  "}
          <a href="https://www.facebook.com/%D9%85%D9%86%D9%8A%D8%B1-%D8%A7%D9%84%D8%B4%D8%B9%D8%B1%D8%A7%D9%86%D9%8A-Mouneer-Alshaarani-153316014743719/">
            <FormattedMessage id="logo.maker.name" />
          </a>
        </div>
        <div
          css={css`
            font-size: 16px;
            color: #f8f2f2;

            p:lang(ar) {
              text-align: right;
              font-size: 15px;
              line-height: 1.5rem;
            }
          `}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  )
}

Logo.propTypes = BioDataContentPropType

export const query = graphql`
  query logo($lang: String = "en-US") {
    contentfulBio(bioId: { eq: "logo" }, node_locale: { eq: $lang }) {
      text {
        text
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default Logo

import PropTypes from "prop-types"

export default {
  data: PropTypes.shape({
    contentfulBio: PropTypes.shape({
      text: PropTypes.shape({
        text: PropTypes.string,
        childMarkdownRemark: PropTypes.shape({
          html: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
}
